import React, { lazy, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import { useCookies } from "react-cookie";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';


const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const UpdatePassword = lazy(() => import("./pages/CreateAccount"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));

function App() {
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  console.log("cookies", cookies.token);
  localStorage.setItem("theme", "white");
  useEffect(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyCQ0SNNvYERVJp0EiiyLmkjCv8-2scf2yc",
      authDomain: "shoppey.firebaseapp.com",
      projectId: "shoppey",
      storageBucket: "shoppey.appspot.com",
      messagingSenderId: "589525245720",
      appId: "1:589525245720:web:954bcb0573e19c2441086d",
      measurementId: "G-WT058VYE9M"
    };

    firebase.initializeApp(firebaseConfig);
  }, []);

  return (
    <>
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          {cookies.token !== undefined ? (
            <>
              {/*<Redirect exact from="/" to="/app" />*/}
              <Route path="/" component={Layout} />
            </>
          ) : (
            <>
              
              <Route exact path="/login" component={Login} />
              <Route path="/forgot-password" component={ForgotPassword} />
              <Route path="/updatepass" component={UpdatePassword} />
              <Redirect from="/" to="/login" />

            </>
          )}
        </Switch>
      </Router>
    </>
  );
}

export default App;
