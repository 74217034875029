import React, {Suspense} from 'react'
import ReactDOM from 'react-dom'
import './assets/css/tailwind.output.css'
import App from './App'
import {SidebarProvider} from './context/SidebarContext'
import ThemedSuspense from './components/ThemedSuspense'
import {Windmill} from '@windmill/react-ui'
import * as serviceWorker from './serviceWorker'

import 'primereact/resources/themes/tailwind-light/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import {RecoilRoot} from "recoil";
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";

// if (process.env.NODE_ENV !== 'production') {
//   const axe = require('react-axe')
//   axe(React, ReactDOM, 1000)
// }


ReactDOM.render(
    <SidebarProvider>
        <RecoilRoot>
            <Suspense fallback={<ThemedSuspense/>}>
                {/*<Windmill usePreferences>*/}
                <DevSupport ComponentPreviews={ComponentPreviews}
                            useInitialHook={useInitial}
                >
                    <App/>
                </DevSupport>
                {/*</Windmill>*/}
            </Suspense>
        </RecoilRoot>
    </SidebarProvider>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
